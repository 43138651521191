<template>
  <div>
    <inception-list></inception-list>
  </div>
</template>

<script>
import InceptionList from './InceptionList.vue';

export default {
  components: {
    InceptionList,
  },

  data() {
    return {};
  },
};
</script>
